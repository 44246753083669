@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto+Mono:wght@500&family=Roboto+Slab:wght@700&display=swap");
.header {
  display: flex;
  padding-left: 4%;
  padding-right: 4%;
  background-color: transparent;
  height: 64px;
  position: fixed;
  z-index: 1;
}

.header.active {
  background-color: rgb(41, 39, 39) !important;
  top: 0;
}

.mobile-menu-list {
  display: none !important;
}

.header-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
    url("../assests/background.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}

.menu-item-list {
  justify-content: end;
  width: 100%;
  background-color: transparent !important;
}

.menu-item-list .active {
  border-bottom: 3px solid #40e0d0;
}

.header-font {
  display: flex;
  line-height: normal;
  width: 130px;
}
.upper {
  font-family: "Roboto Slab", serif;
  color: white;
  font-size: 24px;
  letter-spacing: 6px;
}
.lower {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 6px;
  color: #40e0d0;
}
.logo {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.horizontal-line {
  line-height: normal;
  width: 95%;
  border: none;
  overflow: hidden;
  margin: 0px;
  padding: 0px;

  height: 2px;
  background: #40e0d0;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #40e0d0 !important;
}

.header-button {
  display: flex;
  align-self: center;
  padding-left: 5px;
}

.header .menu-item-list {
  width: 75vw;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hero-text h1 {
  font-family: "Roboto Slab", serif;
  font-size: 52px;
  color: White;
  letter-spacing: 5px;
  text-shadow: 2px 2px black;
}
.hero-text h1 span {
  margin-left: 30px;
  z-index: 5;
}
.hero-text h1:nth-child(2) {
  margin-bottom: 10px;
}

.hero-text h1:nth-child(1) {
  margin-bottom: 0px;
}

.hero-text h3 {
  letter-spacing: 3px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 0.1em 0.3em;
  line-height: normal;
  position: relative;
  color: black;

  background: #40e0d0;
  transform: skewX(-10deg);
  display: inline-block;
}
.circle-image {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 66%;
  top: 10%;
  width: 65%;
  z-index: 2;
}

.header-contact-button {
  display: none !important;
}
.header > ul {
  align-items: center !important;
}
@media only screen and (max-width: 600px) {
  .mobile-menu-list {
    display: flex !important;
    background-color: transparent !important;
    border: 0px !important;
    align-self: center !important;
  }
  .mobile-menu-list li:after {
    border-bottom: none !important;
  }
  .ant-menu-submenu-title span {
    color: white !important;
    font-size: 20px !important;
  }
  .menu-item-list {
    display: none !important;
  }
  .header {
    width: 100%;
    justify-content: space-between;
  }
  .header-button {
    display: none !important;
  }
  .hero-text {
    width: 95%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .hero-text h1 {
    font-family: "Roboto Slab", serif;
    font-size: 32px;
    color: White;
    letter-spacing: 3px;
    text-shadow: 2px 2px black;
  }

  .circle-image {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 68%;
    top: 6%;
    width: 69%;
    z-index: 2;
  }

  .hero-text h1:nth-child(2) {
    font-size: 30px;
  }

  .hero-text h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: normal;
    position: relative;
    color: black;
    font-size: 12px;
    background: #40e0d0;
    transform: skewX(-10deg);
    display: inline-block;
  }

  .header-container {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
      url("../assests/background2.jpg"); */

    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
      url("../assests/background2.jpg") 0 0 / cover;
    height: 100vh;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed; */
  }

  .hero-text .header-contact-button {
    margin-top: 10% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;

    background-color: transparent !important;
    border: 2px solid #40e0d0 !important;
    border-radius: 0% !important;
  }

  .hero-text .header-contact-button span {
    color: #40e0d0 !important;
  }
}

/* .hero-text h1 span {
  padding: 0.1em 0.3em;

  position: relative;
  color: white;
  text-shadow: 2px 2px 2px black;
  background: #40e0d0;
  transform: skewX(-5deg);
  display: inline-block;
} */
