@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto+Mono:wght@500&family=Roboto+Slab:wght@700&display=swap");
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#section1 {
  height: 100vh;
}

.upper-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
}

.upper-container h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  width: 30%;
  position: relative;
  color: black;
  align-self: center;
  background: #40e0d0;
  transform: skewX(-10deg);
}
.upper-container p {
  font-family: "Montserrat", sans-serif;
}

.main-container > h1 {
  margin-top: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}

.lower-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.service-card {
  width: 25%;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 1%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background-color: white;
}

.service-card h1 {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  background: #40e0d0;
  transform: skewX(-10deg);
}

.service-card h2 {
  font-family: "Montserrat", sans-serif;
}

.service-card ul li {
  font-family: "Montserrat", sans-serif;
  list-style: circle;
  text-align: justify;
  text-justify: inter-word;
}
.service-card ul {
  padding-left: 20px;
}

/* .service-card img {
  width: 200px;
  object-fit: cover;
} */
@media only screen and (max-width: 600px) {
  #section1 {
    height: auto;
  }
  .main-container > h1 {
    margin-top: 80px;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .upper-container h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    width: 90%;
    position: relative;
    color: black;
    align-self: center;
    background: #40e0d0;
    transform: skewX(-10deg);
  }

  .upper-container p {
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    text-justify: inter-word;
    width: 80%;
    align-self: center;
  }

  .lower-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .service-card {
    width: 90%;
    margin: 5%;
    padding: 5%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
  }

  .service-card h1 {
    font-size: 22px;
    font-family: "Montserrat", sans-serif;
    background: #40e0d0;
    transform: skewX(-10deg);
  }

  .service-card h2 {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
  }

  .service-card ul li {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    list-style: circle;
    text-align: justify;
    text-justify: inter-word;
  }
}
